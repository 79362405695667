<template>
  <el-dialog
    :visible.sync="visible"
    :title="mode ? '新建校招' : '修改校招'"
    class="yt-dialog yt-dialog-default"
    :close-on-click-modal="false"
    :before-close="close"
    @closed="closed"
  >
    <el-form ref="form" :model="formData" :rules="ruleValidate" label-width="132px" class="yt-form yt-dialog-form">
      <el-form-item label="选择年份" prop="year">
        <el-date-picker v-model="formData.year" type="year" value-format="yyyy" placeholder="请选择" prefix-icon="''"> </el-date-picker>
      </el-form-item>
      <el-form-item label="校招名称" prop="name">
        <template v-if="mode">
          <el-input v-model="formData.name" placeholder="请输入校招名称" />
        </template>
        <template v-else>
          {{ formData.name }}
        </template>
      </el-form-item>
      <el-form-item label="上传图片" class="cover-item" prop="coverUrl">
        <el-upload
          class="cover"
          action=""
          ref="coverUpload"
          accept=".png,.jpg"
          :show-file-list="false"
          :http-request="handleImgSize"
          :on-change="handleChange"
        >
          <template v-if="imageUrl">
            <el-image class="cover" :src="imageUrl"></el-image>
            <div class="upload-update">
              <svg class="icon" aria-hidden="true">
                <use xlink:href="#icon-shangchuan" />
              </svg>
              <p>上传</p>
            </div>
          </template>
          <template v-else>
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-shangchuan" />
            </svg>
            <p>上传</p>
          </template>
        </el-upload>
        <div class="upload-tip">
          建议尺寸:120*90px，或4:3格式，支持jpg、png格式，图片小于5M
        </div>
        <canvas id="uploadCanvas" width="32" height="32" style="display: none"></canvas>
      </el-form-item>
    </el-form>
    <div class="footer" slot="footer">
      <el-button class="button" @click="visible = false">取消</el-button>
      <el-button type="primary" :loading="loading" class="button" @click="submit">确定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import YTIcon from '@components/common/YTIcon'
import jobApi from '@api/job'
import ossApi from '@api/oss'
export default {
  name: 'SchoolRecruitmentCUDialog',
  components: { YTIcon },
  data() {
    const validateName = (rule, value, callback) => {
      let reg = /^(?=.*\S).+$/
      if (!reg.test(value)) {
        callback(new Error('校招名称不能全为空格'))
      } else {
        callback()
      }
    }
    return {
      visible: false,
      mode: true,
      loading: false,
      formData: {
        name: '',
        coverUrl: '',
        year: ''
      },
      ruleValidate: {
        year: [{ required: true, message: '请选择年份' }],
        name: [
          { required: true, message: '校招名称不能为空' },
          { max: 30, message: '校招名称长度不能超过30个字符' },
          { min: 1, message: '校招名称长度不小于1个字符' },
          { validator: validateName, trigger: 'blur' }
        ],
        coverUrl: [{ required: true, message: '请上传图片' }]
      },
      imageUrl: '',
      uploadFile: {},
      uploadParams: {
        key: '',
        policy: '',
        OSSAccessKeyId: '',
        success_action_status: '200',
        Signature: ''
      }
    }
  },
  methods: {
    open(mode, data) {
      this.mode = mode
      this.visible = true
      if (!mode) {
        this.formData = this.$deepCopy(data)
        this.imageUrl = data.coverUrl
      }
    },
    submit() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.loading = true
          let payload = { ...this.formData }
          jobApi
            .updateRecruitment(payload)
            .then(res => {
              if (res.res === true) {
                this.$message.success(this.mode ? '添加成功' : '修改成功')
                this.$emit('refresh')
                this.visible = false
              } else {
                this.loading = false
              }
            })
            .catch(err => {
              this.loading = false
            })
        }
      })
    },
    close(done) {
      done()
    },
    closed() {
      this.$refs.form.resetFields()
      this.formData = {
        name: '',
        coverUrl: '',
        year: ''
      }
      this.imageUrl = ''
      this.loading = false
    },
    handleChange(file) {
      this.uploadFile = file
    },
    handleImgSize() {
      if (this.uploadFile.size > 5120000) {
        this.$message.warning('请上传小于5mb的图片')
        return
      }
      let fileName = this.uploadFile.name.slice(0, -4)
      fileName = fileName.replace(/[ |~`!@#$%^&*()\-_+=\\\[\]{};:"',<.>\/?，。；：“”》《、]/g, '')
      this.uploadFile.name = fileName + (this.uploadFile.raw.type === 'image/png' ? '.png' : '.jpg')
      let file = this.uploadFile.raw
      if (window.uploadUrl) {
        let formData = new FormData()
        formData.append('file', file, this.uploadFile.name)
        ossApi.upCover(formData).then(res => {
          this.imageUrl = res.res
          this.formData.coverUrl = res.res
        })
      } else {
        ossApi.getQuestionBankCoverToken(new Date().valueOf(), this.uploadFile.name).then(res => {
          let data = res.res
          this.uploadParams.key = data.dir
          this.uploadParams.OSSAccessKeyId = data.accessId
          this.uploadParams.policy = data.policy
          this.uploadParams.Signature = data.signature
          let formData = new FormData()
          formData.append('key', this.uploadParams.key)
          formData.append('OSSAccessKeyId', this.uploadParams.OSSAccessKeyId)
          formData.append('policy', this.uploadParams.policy)
          formData.append('Signature', this.uploadParams.Signature)
          formData.append('file', file)
          fetch(`https://${data.host}`, {
            method: 'POST',
            body: formData
          }).then(result => {
            this.imageUrl = `https://${data.host}/${this.uploadParams.key}`
            this.formData.coverUrl = `https://${data.host}/${this.uploadParams.key}`
          })
        })
      }
    }
  }
}
</script>

<style lang="less" scoped>
@import '~@/theme/variables';
.cover {
  width: 120px;
  height: 90px;
  position: unset !important;
  ::v-deep .el-upload {
    p {
      height: 13px;
      line-height: 13px;
    }
  }
  .upload-update {
    width: 120px !important;
    height: 90px !important;
  }
}
.upload-update {
  p {
    height: 13px;
    line-height: 13px;
  }
}
</style>
