<template>
  <div class="yt-main">
    <div class="yt-breadcrumb-wrapper">
      <el-breadcrumb class="yt-breadcrumb">
        <el-breadcrumb-item>招聘管理</el-breadcrumb-item>
        <el-breadcrumb-item>校招管理</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="yt-container">
      <div class="yt-content" v-infinite-scroll="scrollSearchRecruitment" :infinite-scroll-disabled="scrollDisabled" :infinite-scroll-distance="500">
        <ul>
          <li class="create-button" @click="toCreate">
            <YTIcon :href="'#icon-xinzeng'" @click="toCreate" />
            <p>新增校园招聘</p>
          </li>
          <li v-for="item in recruitmentList" :key="item.id" class="item">
            <img :src="item.coverUrl" alt="" />
            <p class="textEllipsis">{{ item.name }}</p>
            <div class="button-list">
              <div class="has-border">
                <YTIcon :href="'#icon-a-bianji'" @click="toUpdate(item)" />
              </div>
              <div>
                <YTIcon :href="'#icon-shanchu'" @click="toDelete(item)" />
              </div>
            </div>
          </li>
        </ul>
        <div class="list-bottom" :class="{ 'list-end': recruitmentList.length === total }">
          <template v-if="recruitmentList.length === total">
            <div></div>
            <p>End</p>
            <div></div>
          </template>
          <template v-else-if="recruitmentLoading && recruitmentList.length < total">
            <el-image :src="require('@assets/loading.gif')" />
          </template>
        </div>
      </div>
    </div>
    <SchoolRecruitmentCUDialog ref="recruitmentCUDialog" @refresh="reSearchRecruitment" />
    <ConfirmDialog ref="deleteDialog" :title="'提示'" @on-ok="deleteRecruitment">
      <div style="width: 260px; margin: 0 auto;">
        <YTIcon :href="'#icon-jingshi'" style="font-size: 18px"></YTIcon>
        确认要删除选中校招吗？
      </div>
    </ConfirmDialog>
  </div>
</template>

<script>
import YTIcon from '@components/common/YTIcon'
import SchoolRecruitmentCUDialog from '@components/common/dialog/SchoolRecruitmentCUDialog'
import ConfirmDialog from '@components/common/dialog/ConfirmDialog'
import jobApi from '@api/job'
export default {
  name: 'SchoolRecruitment',
  components: { YTIcon, SchoolRecruitmentCUDialog, ConfirmDialog },
  data() {
    return {
      recruitmentList: [],
      pageNum: -1,
      pageSize: 10,
      total: 0,
      recruitmentLoading: false,
      toDeleteId: ''
    }
  },
  computed: {
    scrollDisabled() {
      return this.recruitmentList.length === this.total && this.recruitmentList.length > 0 && this.total > 0
    }
  },
  mounted() {},
  methods: {
    scrollSearchRecruitment() {
      if (this.recruitmentLoading) {
        return
      }
      this.pageNum += 1
      this.recruitmentLoading = true
      jobApi
        .getRecruitment(this.pageNum, this.pageSize)
        .then(res => {
          this.recruitmentList = this.recruitmentList.concat(res.res.data)
          this.total = res.res.total
        })
        .finally(() => {
          this.recruitmentLoading = false
        })
    },
    reSearchRecruitment() {
      this.pageNum = -1
      this.recruitmentList = []
      this.scrollSearchRecruitment()
    },
    toCreate() {
      this.$refs.recruitmentCUDialog.open(true)
    },
    toUpdate(row) {
      this.$refs.recruitmentCUDialog.open(false, row)
    },
    toDelete(row) {
      this.toDeleteId = row.jobRecruitId
      this.$refs.deleteDialog.open()
    },
    deleteRecruitment() {
      jobApi.deleteRecruitment(this.toDeleteId).then(res => {
        if (res.res === true) {
          this.$message.success('删除校招成功')
          this.reSearchRecruitment()
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.yt-main {
  padding-top: 0;
}
.yt-container {
  height: 100%;
  padding: 10px;
  .yt-content {
    overflow-y: auto;
    overflow-x: hidden;
  }
  ul {
    margin-right: -16px;
    margin-top: -20px;
    padding-bottom: 10px;
    overflow: hidden;
  }
  li {
    float: left;
    width: 212px;
    height: 230px;
    margin-top: 20px;
    margin-right: 20px;
    padding: 12px;
    position: relative;
    background: #fcfcfc;
    border: 1px solid #ececec;
    border-radius: 6px;
    &.item {
      &:hover {
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
      }
    }
    img {
      width: 100%;
      height: 140px;
      margin-bottom: 10px;
    }
    p {
      line-height: 20px;
      font-size: @medium;
      margin-bottom: 20px;
    }
    .button-list {
      .flexStyle();
      justify-content: space-between;
      line-height: 16px;
      font-size: 16px;
      color: #999;
      div {
        width: 50%;
        text-align: center;
      }
      .has-border {
        padding-right: 12px;
        margin-right: 12px;
        border-right: 1px solid #d9d9d9;
      }
    }
  }
  .create-button {
    .flexStyle();
    flex-direction: column;
    background: #f8f8f8;
    border: 1px dashed #ececec;
    border-radius: 6px;
    color: #999;
    cursor: pointer;
    svg {
      color: #cccccc;
      font-size: 44px;
      margin-bottom: 12px;
    }
  }
  .list-bottom {
    text-align: center;
    color: #d9d9d9;
    &.list-end {
      .flexStyle();
      margin-top: 20px;
      div {
        width: 100px;
        border-bottom: 1px solid #d9d9d9;
      }
      p {
        font-size: @medium;
        margin: 0 30px;
      }
    }
  }
}
@media screen and (max-width: 1439px) {
  .yt-content {
    li {
      width: calc(25% - 20px);
    }
  }
}
@media screen and (min-width: 1440px) {
  .yt-content {
    li {
      width: calc(20% - 20px);
    }
  }
}
</style>
